<template>
  <div
    v-if="!isLoading"
    class="auth-wrapper auth-v1"
    style="background-color: #f4f5fa;"
  >
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="`${url_public}${config.isotipo ? config.isotipo : config.logo}`"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ config.name }}
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            {{ $t('login.title') }}
          </p>
          <p class="mb-2">
            {{ $t('login.subTitle') }}
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="model.email"
              outlined
              :label="$t('lbl.email')"
              placeholder="email@gmail.com"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="model.password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              :label="$t('lbl.password')"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                :label="$t('login.remember')"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forgot link -->
              <router-link
                :to="{ name: 'auth-forgot-password' }"
                class="mt-1"
              >
                {{ $t('login.forgot') }}
              </router-link>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              :loading="loading"
              @click="login()"
            >
              {{ $t('btn.send') }}
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            {{ $t('login.newAccount') }}
          </span>
          <router-link :to="{ name: 'auth-register' }">
            {{ $t('login.createAccount') }}
          </router-link>
        </v-card-text>

        <!-- divider
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text>-->

        <!-- social links
        <v-card-actions class="d-flex justify-center">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions>-->
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <WstIzq
      v-if="name_project === 'wwwww'"
      class="auth-tree"
      width="230"
      height="200"
      :color="$vuetify.theme.themes.light.primary"
    />
    <WstDerecha
      v-if="name_project === 'wwwww'"
      class="auth-tree-3"
      width="250"
      height="150"
      :color="$vuetify.theme.themes.light.primary"
    />

    <ProIzq
      v-if="name_project === 'ppppp'"
      class="auth-tree"
      width="230"
      height="200"
      :color="$vuetify.theme.themes.light.primary"
    />
    <ProDerecha
      v-if="name_project === 'ppppp'"
      class="auth-tree-3"
      width="250"
      height="150"
      :color="$vuetify.theme.themes.light.primary"
    />

    <KroIzq
      v-if="name_project === 'kkkkk'"
      class="auth-tree"
      width="230"
      height="160"
      :color="$vuetify.theme.themes.light.primary"
    />
    <KroDerecha
      v-if="name_project === 'kkkkk'"
      class="auth-tree-3"
      width="250"
      height="180"
      :color="$vuetify.theme.themes.light.primary"
    />

    <TravelzunIzq
      v-if="name_project === 'ttttt'"
      class="auth-tree"
      width="230"
      height="160"
      :color="$vuetify.theme.themes.light.primary"
    />
    <TravelzunDerecha
      v-if="name_project === 'ttttt'"
      class="auth-tree-3"
      width="250"
      height="180"
      :color="$vuetify.theme.themes.light.primary"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import themeConfig from '@themeConfig'

// eslint-disable-next-line import/no-unresolved
import ProDerecha from '@/views/utils/images/pro_derecha.vue'
// eslint-disable-next-line import/no-unresolved
import ProIzq from '@/views/utils/images/pro_izq.vue'
// eslint-disable-next-line import/no-unresolved
import KroDerecha from '@/views/utils/images/kro_derecha.vue'
// eslint-disable-next-line import/no-unresolved
import KroIzq from '@/views/utils/images/kro_izq.vue'
// eslint-disable-next-line import/no-unresolved
import WstIzq from '@/views/utils/images/wst_izq.vue'
// eslint-disable-next-line import/no-unresolved
import WstDerecha from '@/views/utils/images/wst_derecha.vue'
// eslint-disable-next-line import/no-unresolved
import TravelzunIzq from '@/views/utils/images/travelzun_izq.vue'
// eslint-disable-next-line import/no-unresolved
import TravelzunDerecha from '@/views/utils/images/travelzun_derecha.vue'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      email,
      password,
      socialLink,

      // themeConfig
      appLogo: themeConfig.app.logo,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },

  components: {
    ProDerecha,
    ProIzq,
    KroDerecha,
    KroIzq,
    WstIzq,
    WstDerecha,
    TravelzunIzq,
    TravelzunDerecha,
  },

  data() {
    return {
      config: {
        logo: null,
        name: '',
      },
      isLoading: true,
      url_public: process.env.VUE_APP_API_URL_PLUBLIC,
      name_project: process.env.VUE_APP_PROJ_NAME,
      model: {
        email: null,
        password: null,
      },
      loading: false,
    }
  },
  mounted() {
    this.load()

    // console.log(this.$vuetify.theme.themes.light.primary)
  },
  methods: {
    load() {
      this.axios
        .get('configuration-view/data-general')
        .then(res => {
          if (res.data.success) {
            this.config = res.data.data.data.data

            this.$vuetify.theme.themes.light.primary = this.config.color
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    login() {
      // eslint-disable-next-line no-empty
      if (this.model.email && this.model.password) {
        this.loading = true
        // eslint-disable-next-line no-undef
        this.axios
          .post('login', this.model)
          .then(response => {
            if (response.data.success === false) {
              if (response.data.data.status === 404 || response.data.data.status === 400) {
                this.$toast.error(this.$t('msg.invalidCredentials'))
              } else if (response.data.data.status === 401) {
                this.$toast.error(this.$t('msg.userInactive'))
              }
            } else if (response.data.data.api_token) {
              // this.$store.state.app.user = response.data.data
              // console.log(response.data.data)

              // UPDATE DATA
              if (response.data.data.currency_code) {
                localStorage.setItem('currency_code', response.data.data.currency_code)
              }
              if (response.data.data.currency_symbol) {
                localStorage.setItem('currency_symbol', response.data.data.currency_symbol)
              }
              if (response.data.data.leng) {
                localStorage.setItem('lenguaje', response.data.data.leng)
              }

              sessionStorage.setItem('sanctum_auth', response.data.data.api_token)
              if (response.data.data.nivel === -1) {
                // console.log(response.data.data)
                if (response.data.data.id_father === null) {
                  const permisos = response.data.data.perfil_afiliado.permisos.abilities
                  permisos.push('exportation:list')
                  sessionStorage.setItem('permisos_auth', permisos)
                } else {
                  // sessionStorage.setItem('permisos_auth', response.data.data.role.permisos.abilities)
                  const permisos = response.data.data.permisos.abilities
                  permisos.push('exportation:list')
                  sessionStorage.setItem('permisos_auth', permisos)
                }

                // sessionStorage.setItem('perfil', response.data.data.perfil_afiliado.slug)
                if (response.data.data.tableros.length > 0) {
                  sessionStorage.setItem('perfil', response.data.data.tableros[0].slug)
                }
              } else if (response.data.data.nivel === 0) {
                // sessionStorage.setItem('permisos_auth', response.data.data.role.permisos.abilities)
                sessionStorage.setItem('permisos_auth', response.data.data.perfil_afiliado.permisos.abilities)

                // sessionStorage.setItem('perfil', 'agencia')
                // sessionStorage.setItem('perfil', response.data.data.perfiles[0].slug)
                if (response.data.data.tableros.length > 0) {
                  sessionStorage.setItem('perfil', response.data.data.tableros[0].slug)
                }
              } else if (response.data.data.nivel === 1) {
                if (response.data.data.id_father === null) {
                  sessionStorage.setItem('permisos_auth', response.data.data.perfiles[0].permisos.abilities)
                } else {
                  // sessionStorage.setItem('permisos_auth', response.data.data.role.permisos.abilities)
                  sessionStorage.setItem('permisos_auth', response.data.data.permisos.abilities)
                }

                // sessionStorage.setItem('perfil', response.data.data.perfiles[0].slug)
                if (response.data.data.tableros.length > 0) {
                  sessionStorage.setItem('perfil', response.data.data.tableros[0].slug)
                }
              } else if (response.data.data.nivel === 2) {
                // console.log(response.data.data)
                if (response.data.data.id_father === null) {
                  sessionStorage.setItem('permisos_auth', response.data.data.agente.perfil.permisos.abilities)
                } else {
                  // sessionStorage.setItem('permisos_auth', response.data.data.role.permisos.abilities)
                  sessionStorage.setItem('permisos_auth', response.data.data.permisos.abilities)
                }

                sessionStorage.setItem('perfil', 'agente')

                // sessionStorage.setItem('perfil', response.data.data.agente.perfil.slug)
                /* if (response.data.data.tableros.length > 0) {
                  sessionStorage.setItem('perfil', response.data.data.tableros[0].slug)
                } */
              }

              this.$router.push({ name: 'dashboard', params: { perfil: sessionStorage.getItem('perfil') } })

              setTimeout(() => {
                window.location.reload()
              }, 1000)
            }
          })
          .catch(error => console.log(error))

          // eslint-disable-next-line no-return-assign
          .finally(() => (this.loading = false))
      } else {
        this.$toast.error(this.$t('msg.dataRequiere'))
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
